a {
    color: inherit;
    text-decoration: none;
}

.card-header {
    font-weight: bold;
    font-size: 1.2em;
}

h1 {
    text-align: center;
}